* {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  background: 0 0;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
*::-webkit-scrollbar {
  display: none !important;
}
b,
strong {
  font-weight: bold;
}
ol,
ul {
  list-style: none;
}

blockquote {
  quotes: none;
}

blockquote:after,
blockquote:before {
  content: "";
  content: none;
}

del {
  text-decoration: line-through;
}

address,
article,
aside,
footer,
nav,
section {
  display: block;
}

body {
  font-family: dm_sansregular;
  font-size: 1.0625rem;
  color: #333;
  overflow-x: hidden;
  min-width: 20rem;
  position: relative;
}

input,
select,
textarea {
  font-family: dm_sansregular;
}

a {
  color: #333;
}

.submit:hover {
  -moz-opacity: 0.85;
  -khtml-opacity: 0.85;
  opacity: 0.85;
}

.thumb {
  display: block;
}

.thumb img {
  display: block;
  width: 100%;
}
.modal-open {
  /* overflow: hidden !important; */
}

.modal-actived {
  overflow: hidden !important;
}
p {
  line-height: 1.6em;
  font-size: 1rem;
  font-family: dm_sansregular;
}

.hidden {
  display: none;
}

br.clear {
  clear: both;
  line-height: 0;
}

i {
  font-size: 18.5px;
}
img {
  width: 100%;
  display: block;
}

.wrapper {
  width: 75%;
  margin: 0 auto;
  max-width: 1440px;
}

@font-face {
  font-display: auto;
  font-family: "dm_sansregular";
  src: url(../fonts/dmsans-regular.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-display: auto;
  font-family: "dm_sansboldmedium";
  src: url(../fonts/dmsans-medium.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "dm_sansboldbold";
  src: url(../fonts/dmsans-bold.woff) format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


/* contact us button style */
#button-parent {
  width: 130px !important;
}
@media all and (max-width : 980px) {
  #button-parent {
    width: max-content !important;
    margin: 0 auto !important;
  }
}
#contact-us-btn{
  width: 130px !important;
}

/* toastify parent element */
#active .Toastify__toast-container--bottom-right {
  position: absolute !important;
  bottom: 6.5% !important;
}

.slick-list {
  border-radius: 30px 60px 30px 60px;
}

.slick-initialized .slick-slide {
}

.slick-slide div {
  margin-right: 10px !important;
}

.slick-prev ::before {
  display: none !important;
}

.slick_slider button {
  display: none !important;
}

.slick-list {
  border-radius: unset;
}

.slick-list .team {
  overflow: unset !important;
}

.slick-slider {
  width: 100%;
}

.dTMJTp {
  height: 350px !important;
}
.focused-field {
  border-color: #ff5e48 !important;
}

.OtpInput {
  width: 75px !important;
  height: 55px !important;
  font-size: 24px;
  font-family: "dm_sansregular";
  color: #282C3F;
  border: 2px solid #E4E4E4;
  border-radius: 8px;
  margin-bottom: 10px;
}
@media all and (max-width: 540px) {
  .OtpInput {
    width: 70px !important;
    height: 55px !important;
  }
}

@media all and (max-width:400px) {
  .OtpInput {
    width: 60px !important;
    height: 50px !important;
  }
}

@media all and (max-width: 980px) {
  .OtpInput {
    font-size: 18px;
  }
}

@media all and (max-width: 480px) {
  .wrapper {
    width: 90%;
  }
}

@media all and (max-width: 360px) {
  .OtpInput {
    margin-right: 5px;
    font-size: 16px;
  }
}
.react-reveal.laOLsv {
  margin: 0 auto;
}

html.modal-enabled {
  overflow: hidden !important;
}

/* reactcalendar */

.react-calendar {
  width: 350px;
  border: none !important;
  background: #fff !important;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px; */
  margin: 10px auto;
  /* border-radius: 10px; */
  /* background: #fcfcfc !important;
      padding: 20px; */
}
@media all and (max-width: 480px) {
  .react-calendar {
    width: 100%;
  }
  .react-calendar__month-view__weekdays {
    font-size: 15px;
  }
}
@media all and (max-width: 360px) {
  .react-calendar__month-view__weekdays {
    font-size: 13px;
  }
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  flex-grow: unset;
}

.react-calendar__tile--active {
  background: #fc8b62 !important;
  color: unset !important;
  border-radius: 50%;
  color: #fff !important;
}

.react-calendar__tile:disabled {
  background-color: #d9dad7;
  opacity: 0.3;
  cursor: not-allowed;
}
button.react-calendar__month-view__days__day--weekend {
  color: #fff !important;
}
.react-calendar__navigation button:disabled {
  background-color: #d9dad7;
}

button.react-calendar__tile {
  color: #000;
}
.react-calendar__tile {
  padding: 16px 10.6667px !important;
}
button.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}
.react-calendar__tile--now {
  background: #8c9bd1;
  color: rgb(0, 0, 0) !important;
}
button.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

button.react-calendar__navigation__next-button,
button.react-calendar__navigation__prev-button,
.react-calendar__navigation__label__labelText--from {
  color: rgb(0, 0, 0) !important;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
  width: 15px;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

html.modal-enabled {
  overflow: hidden !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@media all and (max-width: 480px) {
  .slick-slide div #hello {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 0 !important;
  }
  .slick-slide div {
    margin-right: 0 !important;
  }
}
.fTdgod  video{
  height: 60vh !important;
}
